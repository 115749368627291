@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@400;700&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #1B1C1D; */
  box-sizing: border-box;
  user-select: none;
}

code {
  font-family: Roboto, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mb-0 {
  margin-bottom: 0;
}
